import React from "react";

import ButtonSpotlio2 from "./skins/spotlio2";
import ButtonVail from "./skins/vail";
import ButtonSteamboat from "./skins/steamboat";
import ButtonOnlyLink from "./skins/only-link";
import ButtonDefault from "./skins/default";
interface ButtonProps {
  config: {
    label: string;
    href?: string;
    backgroundColor: string;
    borderColor: string;
    foregroundColor: string;
    skin: string;
    target?: string;
  };
  disabled?: boolean;
  variant?: Variant;
  className?: any;
  linkClassName?: string;
  loading?: boolean;
  onClick?: any;
}

type Variant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "button"
  | "overline";

export default class Button extends React.Component<ButtonProps> {
  render() {
    const {
      linkClassName,
      className,
      children,
      disabled,
      variant,
      config,
      loading,
      onClick,
    } = this.props;

    if (!config) {
      return null;
    }

    const {
      backgroundColor,
      borderColor,
      foregroundColor,
      href,
      label,
      skin = "default",
      target,
    } = config;

    if (!label) {
      return null;
    }

    let toTarget = "_self";
    if (href && href.indexOf("://") !== 0) {
      toTarget = "_blank";
    }

    if (target !== "") {
      toTarget = target;
    }

    if (skin === "spotlio2") {
      return (
        <ButtonSpotlio2
          loading={loading}
          disabled={disabled}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          borderColor={borderColor}
          linkClassName={linkClassName}
          href={href}
          toTarget={toTarget}
          className={className}
          label={label}
          children={children}
          onClick={onClick}
        />
      );
    }

    if (skin === "vail") {
      return (
        <ButtonVail
          disabled={disabled}
          variant={variant}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          foregroundColor={foregroundColor}
          linkClassName={linkClassName}
          href={href}
          toTarget={toTarget}
          className={className}
          label={label}
          children={children}
        />
      );
    }

    if (skin === "steamboat") {
      return (
        <ButtonSteamboat
          disabled={disabled}
          variant={variant}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          foregroundColor={foregroundColor}
          linkClassName={linkClassName}
          href={href}
          toTarget={toTarget}
          className={className}
          label={label}
          children={children}
        />
      );
    }

    if (skin === "only-link") {
      return (
        <ButtonOnlyLink
          disabled={disabled}
          variant={variant}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          foregroundColor={foregroundColor}
          linkClassName={linkClassName}
          href={href}
          toTarget={toTarget}
          className={className}
          label={label}
          children={children}
        />
      );
    }

    return (
      <ButtonDefault
        disabled={disabled}
        variant={variant}
        borderColor={borderColor}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        linkClassName={linkClassName}
        href={href}
        toTarget={toTarget}
        className={className}
        label={label}
        children={children}
      />
    );
  }
}
