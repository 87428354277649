import React, { useState } from "react";
import { navigate } from "gatsby";
import Icon from "../../components/icons";
import Header from "../../modules/header/skins/spotlio2";
import Layout from "../../components/layout";
import Button from "../../components/button";
import Typography from "../../components/utils/typography";
import Container from "../../components/utils/container";
import Modal from "../../components/modal";
import Currency from "../../components/currency";
import * as styles from "./styles.module.scss";

const SeasonPasses = [
  {
    image:
      "https://s3-alpha-sig.figma.com/img/7f71/1e04/d5a12193b931d9612cc881d501bfdde3?Expires=1630886400&Signature=GTjHis0SHf9~KxzL4gIysA3KR~ah5azc0l~A-uNFGmL4mlh8L8FW0ENKkuEsApbYdt6hzTbm0rz3ulvNVa3UHbuviMPWdpPBQx0wpWLcAXGbmlFe2FnoREFu9kJulPVzs2~rrXwE5TrrTnIJHXZmuXg3F0-lE9oj3QxH-l5K5lOzt87dKkcCU~cqk8U0kb8NY3CzuDiQyEAAcGMYMMVY31GVX6AhLhE~I~E~or-fLOZlc-~5LBPUH50Xb29wzz-0bru4xFJO8eFbCK-AJ8MQyz2vmeeEobXTZGS9M-4BSZdo-3ClY7T~MTi1UvC9BpqyJpHPYnlIHshQkxks0V2ARg__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "SESONGKORT FAMILIE *",
    price: "11900",
    features: [
      "Ubegrenset kjøring for vintersesongen 2021-22",
      "Inntil 2 voksne (over 18 år)",
      "Inntil 4 junior (7-17 år)",
    ],
    condition: "*alle må være i samme husholdning",
  },
  {
    image:
      "https://s3-alpha-sig.figma.com/img/bfac/c05d/9b63039ffa11d0223418e8f7fd729540?Expires=1630886400&Signature=UJQr0dUt5ez~4gylFPsMAvvAIvITEUTp27EhsCx9H7LapsXgmHYAyr~hXfvJZOisqqslV8YQwLThGmitZSrTRTDnS7zy7~QBFb8x5hCR~AR4HwUqXMv2Rw7XPXyZa2UceveM9fOmDVEDofLjCwQGJ3OHzbM4xZtyMQPQ3OVvTW4WE-zj8yybnO~lsZmHkasMLcY0N4Ngxr6oCJqjeNllDd4~6vILg-K5DWvADpfVOHWJgeLbvYiuO88wzwu9x6KIEN4GdTZZvTf1uTiRiWKf2txZ4guYGTa88Ql9K7Hs6z3g~TICfb8hhfx-AJb4g4XTtBqlhH7Ew4uNwOUMeYdkWQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "Sesongkort voksen (+18)",
    price: "3500",
    features: ["Ubegrenset kjøring for vintersesongen 2021-22"],
  },
  {
    image:
      "https://s3-alpha-sig.figma.com/img/1dd0/3d03/7b6b495e1568b40b16f23ebb9df36c3a?Expires=1630886400&Signature=gfkaLrqQTUAVz-TM-bXj1DP4zcgaAF1bhNj6bEm99cM1VVJGC57mMUz1282pWGuvM6kOMleDtbmNnEx-fI2g1i9VqYEQv6Kc8xyBV0MmbIBXAnRSEDkUm1tsVC6kkTmynJNRQAT8lNnWBCZ5fL1PkOMiWynt6Gavqi3UxCCncElm6yXHWeU7tTSRTXA93p7lUL4V-87oN7r2-pTZA9XdjE3DAImtJpACFweGV5U9b4Q4qpkQFuR~grjmn0ItdisRfWIK74nAS4mXVDEVLnT9jTJ2Stn6-YZiCZLRIL5ZAaK5kGrBWJID1pBBSU7Wuk5FjhBFcGCUaIW3kI4dw9QvVQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
    title: "sesongkort JUNIOR (7-17)",
    price: "3000",
    features: ["Ubegrenset kjøring for vintersesongen 2021-22"],
  },
];

const SeasonPass = (props) => {
  const [cart, setCart] = useState([]);
  const [bundle, setBundle] = useState(false);
  const [adults, setAdults] = useState(0);
  const [junior, setJunior] = useState(0);
  const [addedProducts, setAddedProducts] = useState(false);

  const addToCart = (prod) => {
    let auxCart = [];
    auxCart.push(prod.title);
    setCart(auxCart);
    setBundle(!bundle);
  };

  const addBundleToCart = () => {
    let auxCart = cart;
    auxCart.push("Sesongkort Høyt");
    setCart(auxCart);
    setAddedProducts(true);
  };

  return (
    <Layout>
      <section className={styles.seasonPass}>
        <Header
          config={{
            title: "season pass",
            image: {
              url: "https://s3-alpha-sig.figma.com/img/bf1d/416f/46e0109ce742b661e8c6de02cc1246f2?Expires=1630886400&Signature=KWGeCWjYlw3IDgPSfZ~VDG7xF-V2tMbfnmRhUIfdkw2PgtWau6D0Cn9CY~LBmAEjyLJ9SYuP2Um7qRSk1MLKNI2xpWonPK64XvxwCa8BVoYfWKCepF9wBlGUQWj8y03YiBvxCktZBCteF08339vCnC3C8YPC1XOBXAPy7tFRHwOe2gj1CdL7Rujv5OWQupziLTgF~XxuptaAhol4-f0L-MoS3fc4~o1104axqlXVx2SCslfBOIfwWRjyX7Jzot8yQ56L9MJrMC9FKheIiQ6FbayYD3lQTj5rDpCLdfsUwBRaJNgIV5dvNkVrs35xq8IPkhvbxBLqEgoWWGeYz94l7w__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA",
            },
            textColor: "#ffffff",
          }}
        />
        <Container component="div" maxWidth="lg" className={styles.productList}>
          {SeasonPasses.map((prod, index) => {
            return (
              <div className={styles.product} key={`seasonPass_${index}`}>
                <div className={styles.image}>
                  <img src={prod.image} alt="test" />
                </div>
                <div className={styles.productInfo}>
                  <Typography
                    className={styles.productName}
                    component="h2"
                    variant="h4-heavy"
                  >
                    {prod.title}
                  </Typography>
                  <Currency
                    currency="kr"
                    price={prod.price}
                    template="featuredProductSpotlioTwo"
                  />

                  <div className={styles.features}>
                    {prod.features.map((feature, idx) => {
                      return (
                        <div
                          className={styles.feature}
                          key={`season-pass-feature-${idx}`}
                        >
                          <div className={styles.check}>
                            <Icon icon="check" size="Small" />
                          </div>
                          <Typography component="span" variant="p-line">
                            {feature}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                  {prod.condition && (
                    <Typography
                      className={styles.conditions}
                      variant={"p-line"}
                    >
                      {prod.condition}
                    </Typography>
                  )}
                </div>
                <Button
                  onClick={() => addToCart(prod)}
                  className={styles.productCTA}
                  config={{
                    label: "Legg i handlekurv",
                    backgroundColor: "#3595BA",
                    borderColor: "#3595BA",
                    foregroundColor: "#FFFFFF",
                    skin: "spotlio2",
                  }}
                  disabled={false}
                />
              </div>
            );
          })}
        </Container>
      </section>
      {bundle && (
        <Modal closeFn={() => setBundle(false)}>
          <div className={styles.bundle}>
            {addedProducts ? (
              <div className={styles.productAdded}>
                <span className={styles.shoppingCart}>
                  <Icon icon="ShoppingCart" size="Small" />
                </span>
                <Typography
                  component="p"
                  variant="p-heavy"
                  className={styles.addedLabel}
                >
                  {/* You added one or more items to your cart */}
                  Du har lagt et nytt produkt i handlekurven:
                </Typography>
                {cart.length &&
                  cart.map((item) => {
                    return (
                      <Typography
                        component="p"
                        variant="p-medium"
                        className={styles.itemSummary}
                      >
                        {item}
                      </Typography>
                    );
                  })}
              </div>
            ) : (
              <>
                <Typography
                  component="p"
                  variant="p-heavy"
                  className={styles.bundleTitle}
                >
                  {/* Climbing Park Season Pass */}
                  Sesongkort Høyt&Lavt Klatrepark Bjorli
                </Typography>
                <Typography
                  component="p"
                  variant="p-medium"
                  className={styles.bundleDescription}
                >
                  {/* Would you like to add it to your cart? */}
                  Legge til i handlekurven?
                </Typography>
                <div className={styles.bundles}>
                  <div className={styles.option}>
                    <Typography
                      component="span"
                      variant={"p-heavy"}
                      className={styles.optionName}
                    >
                      {/* Adults */}
                      Voksen
                    </Typography>
                    <Typography
                      component="span"
                      variant={"h4-heavy"}
                      className={styles.optionCurrency}
                    >
                      kr1,500.00
                    </Typography>
                    <Typography
                      component="span"
                      variant={"p-medium"}
                      className={styles.optionDescription}
                    >
                      {/* Ages 18 or above */}
                      Over 18 år
                    </Typography>
                    <div className={styles.quantityPicker}>
                      {adults > 0 && (
                        <div
                          className={styles.quantityBtn}
                          role="button"
                          tabIndex={0}
                          onClick={() => setAdults(adults - 1)}
                          onKeyPress={() => setAdults(adults - 1)}
                        >
                          <Icon icon="Minus" size="Small" />
                        </div>
                      )}
                      <Typography
                        className={styles.quantity}
                        variant="p-heavy"
                        component="span"
                      >
                        {adults}
                      </Typography>
                      <div
                        className={styles.quantityBtn}
                        role="button"
                        tabIndex={0}
                        onClick={() => setAdults(adults + 1)}
                        onKeyPress={() => setAdults(adults + 1)}
                      >
                        <Icon icon="Plus" size="Small" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.optionDivider} />
                  <div className={styles.option}>
                    <Typography
                      component="span"
                      variant={"p-heavy"}
                      className={styles.optionName}
                    >
                      Junior
                    </Typography>
                    <Typography
                      component="span"
                      variant={"h4-heavy"}
                      className={styles.optionCurrency}
                    >
                      kr1,200.00
                    </Typography>
                    <Typography
                      component="span"
                      variant={"p-medium"}
                      className={styles.optionDescription}
                    >
                      {/* Ages below 18 */}
                      4-17 år
                    </Typography>
                    <div className={styles.quantityPicker}>
                      {junior > 0 && (
                        <div
                          className={styles.quantityBtn}
                          role="button"
                          tabIndex={0}
                          onClick={() => setJunior(junior - 1)}
                          onKeyPress={() => setJunior(junior - 1)}
                        >
                          <Icon icon="Minus" size="Small" />
                        </div>
                      )}
                      <Typography
                        className={styles.quantity}
                        variant="p-heavy"
                        component="span"
                      >
                        {junior}
                      </Typography>
                      <div
                        className={styles.quantityBtn}
                        role="button"
                        tabIndex={0}
                        onClick={() => setJunior(junior + 1)}
                        onKeyPress={() => setJunior(junior + 1)}
                      >
                        <Icon icon="Plus" size="Small" />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.bundleCTA}>
            <span
              role="button"
              tabIndex={0}
              onClick={() =>
                addedProducts ? setBundle(false) : setAddedProducts(true)
              }
              onKeyPress={() =>
                addedProducts ? setBundle(false) : setAddedProducts(true)
              }
            >
              <Typography
                component={"p"}
                variant={"p-heavy"}
                className={styles.skipBundle}
              >
                {addedProducts
                  ? /* "Continue shopping" */ "Fortsette å handle"
                  : /* "Continue without adding" */ "Fortsett uten å legge til"}
                <span className={styles.skipIcon}>
                  <Icon icon="ArrowRight" size="Small" />
                </span>
              </Typography>
            </span>
            <div
              role="button"
              className={styles.cartCTA}
              tabIndex={0}
              onClick={() =>
                addedProducts ? navigate("/cart") : addBundleToCart()
              }
              onKeyPress={() =>
                addedProducts ? navigate("/cart") : addBundleToCart()
              }
            >
              <Typography
                component={"p"}
                variant={"h4-heavy"}
                className={styles.addToCart}
              >
                {addedProducts
                  ? /* "Go to cart" */ "Gå til handlekurven"
                  : /* "Add to cart" */ "LEGG I HANDLEKURV"}
              </Typography>
              <span className={styles.cartIcon}>
                <Icon icon="ShoppingCart" size="Small" />
              </span>
            </div>
          </div>
        </Modal>
      )}
    </Layout>
  );
};

export default SeasonPass;
