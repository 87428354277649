import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import DotsAnimation from "../../../animations/dotsAnimation";
import Icon from "../../../icons";
import Typography from "../../../utils/typography";
import * as styles from "../../styles.module.scss";

interface Spotlio2ButtonProps {
  disabled?: boolean;
  loading?: boolean;
  backgroundColor?: string;
  foregroundColor?: string;
  linkClassName?: any;
  href?: string;
  toTarget?: string;
  className?: any;
  label?: string;
  children?: any;
  onClick?: any;
  borderColor?: any;
}

const Spotlio2Button = (props: Spotlio2ButtonProps) => {
  const {
    disabled,
    backgroundColor,
    foregroundColor,
    linkClassName,
    href,
    toTarget,
    className = "",
    label,
    children,
    loading,
    onClick,
    borderColor = "transparent",
  } = props;

  const ButtonSpotlioTwo = styled.button`
    background: ${disabled || loading ? "#D8D8DC" : backgroundColor};
    color: ${disabled || loading ? "#FFFFFF" : foregroundColor};
    border: 1px solid ${disabled || loading ? "#D8D8DC" : borderColor};
    svg {
      * {
        stroke: ${disabled || loading ? "#FFFFFF" : foregroundColor};
      }
    }
  `;

  if (href) {
    return (
      <Link
        className={linkClassName}
        to={href}
        style={{ textDecoration: "none" }}
        target={toTarget}
      >
        <ButtonSpotlioTwo
          className={`${styles.cta__spotlio2} ${className}`}
          disabled={disabled || loading}
        >
          <Typography component="span" variant={"p-heavy"}>
            {label || children}
          </Typography>
          <Icon icon="ArrowRight" size="Small" color={"white"} />
        </ButtonSpotlioTwo>
      </Link>
    );
  }

  return (
    <ButtonSpotlioTwo
      onClick={() => onClick()}
      className={`${styles.cta__spotlio2} ${className} ${
        disabled ? styles.disabled : ""
      }`}
      disabled={disabled || loading}
    >
      {loading ? (
        <DotsAnimation className={styles.loader} />
      ) : (
        <>
          <Typography component="span" variant={"p-heavy"}>
            {label || children}
          </Typography>
          <Icon icon="ArrowRight" size="Small" color={"white"} />
        </>
      )}
    </ButtonSpotlioTwo>
  );
};

export default Spotlio2Button;
