import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Icon from "../../../icons";
import Typography from "../../../utils/typography";
import * as styles from "../../styles.module.scss";

interface SteamboatButtonProps {
  disabled?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  foregroundColor?: string;
  linkClassName?: any;
  href?: string;
  toTarget?: string;
  className?: any;
  label?: string;
  children?: any;
  variant: any;
}

const SteamboatButton = (props: SteamboatButtonProps) => {
  const {
    disabled,
    backgroundColor,
    borderColor,
    foregroundColor,
    linkClassName,
    href,
    toTarget,
    className,
    label,
    children,
    variant,
  } = props;

  const ButtonSteamboat = styled.button`
    color: ${backgroundColor || "#FFFFFF"};
    border-color: ${borderColor || "#FFFFFF"};
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 50%,
      ${backgroundColor || "#FFFFFF"} 50%
    );
    background-size: 201% 100%;
    :hover {
      color: ${foregroundColor || "#242426"};
    }
  `;

  const Chevron = styled.div`
    background: ${backgroundColor || "#FFFFFF"};
    svg {
      color: ${foregroundColor || "#242426"};
    }
  `;

  return (
    <Link to={href} style={{ textDecoration: "none" }} target={toTarget}>
      <ButtonSteamboat
        className={`${styles.cta__steamboat} ${className}`}
        disabled={disabled}
      >
        <Typography
          component="span"
          variant={variant || "body2"}
          className={styles.text}
        >
          {label || children}
        </Typography>
        <Chevron className={styles.chevron}>
          <Icon icon="ChevronRight" size="Small" color={"white"} />
        </Chevron>
      </ButtonSteamboat>
    </Link>
  );
};

export default SteamboatButton;
