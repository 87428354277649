import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import Typography from "../../../utils/typography";

import * as styles from "../../styles.module.scss";

interface VailButtonProps {
  disabled?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  foregroundColor?: string;
  linkClassName?: any;
  href?: string;
  toTarget?: string;
  className?: any;
  label?: string;
  children?: any;
  variant: any;
}

const VailButton = (props: VailButtonProps) => {
  const {
    disabled,
    backgroundColor,
    borderColor,
    foregroundColor,
    linkClassName,
    href,
    toTarget,
    className,
    label,
    children,
    variant,
  } = props;

  const ButtonVail = styled.button`
    background: ${disabled ? "#D8D8DC" : backgroundColor};
    border-color: ${disabled ? "#D8D8DC" : borderColor};
    color: ${disabled ? "#FFFFFF" : foregroundColor};
  `;

  return (
    <Link
      className={linkClassName}
      to={href}
      style={{ textDecoration: "none" }}
      target={toTarget}
    >
      <ButtonVail
        className={`${styles.cta__vail} ${className}`}
        disabled={disabled}
      >
        <Typography component="span" variant={variant || "body2"}>
          {label || children}
        </Typography>
      </ButtonVail>
    </Link>
  );
};

export default VailButton;
