import React from "react";
import Container from "@material-ui/core/Container";
import styled from "styled-components";

import { Typography } from "../../../../components/utils";

import * as styles from "./styles.module.scss";

interface HeaderProps {
  config: any;
}

export default class Header extends React.Component<HeaderProps> {
  render() {
    const {
      title,
      description,
      cta,
      image,
      textColor,
      video,
      transparencyColor,
      transparencyOpacity,
      margin,
    } = this.props.config;
    const HeaderStyles = styled.header`
      color: ${textColor};
      margin: ${margin};
    `;

    const Transparency = styled.div`
      background-color: ${transparencyColor};
      opacity: ${transparencyOpacity};
    `;

    // TODO: spotlio icons
    const icons = {
      chevrons:
        "https://ik.imagekit.io/spotlio/bjorli/icons/chevrons-down_AwhJ9fMmsnN.png",
    };

    return (
      <HeaderStyles className={styles.header}>
        {video ? (
          <video
            poster={image && image.url}
            className={styles.media}
            autoPlay
            loop
            muted
          >
            <source src={video} type="video/mp4" />
          </video>
        ) : (
          <img
            src={image.url}
            className={styles.media}
            alt={image.alternativeText}
          />
        )}
        <Transparency className={styles.transparency} />
        <Container className={styles.headerContent}>
          <Typography className={styles.title} component={"h1"} variant={"h1"}>
            {title}
          </Typography>
          <div className={styles.chevrons}>
            <img src={icons.chevrons} alt="scroll down" />
          </div>
        </Container>
      </HeaderStyles>
    );
  }
}
